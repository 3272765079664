<template>
  <add-tenant-information-section
    :initial-data="selectedTenantData"
    has-id-card-input
    is-readonly
    ref="tenantInformation"
  >
    <bg-grid-item :col="6">
      <add-tenant-contract-id-input
        v-model="contractIdKeyword"
        required
        :listings="listings"
        :listing-id="listingId"
        :disabled="isDisabledContractIdField"
        :is-allow-contract-in-different-listing="
          isAllowContractInDifferentListing
        "
        @select="selectContract"
      />
    </bg-grid-item>
  </add-tenant-information-section>
</template>

<script>
import { BgGridItem } from 'bangul-vue';

import AddTenantInformationSection from '../AddTenantInformationSection';
import AddTenantContractIdInput from '../AddTenantContractIdInput';

export default {
  name: 'AddTenantInformationByContract',
  components: {
    BgGridItem,
    AddTenantInformationSection,
    AddTenantContractIdInput,
  },
  props: {
    listingId: {
      type: Number,
      default: 0,
    },
    listings: {
      type: Array,
      required: true,
    },
    isAllowContractInDifferentListing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contractIdKeyword: '',
      selectedContract: null,
    };
  },
  watch: {
    listingId() {
      if (!this.isAllowContractInDifferentListing) {
        this.selectedContract = null;
        this.contractIdKeyword = '';
      }
    },
    selectedContract: {
      deep: true,
      handler(contract) {
        this.$emit('contract-changed', contract);
      },
    },
  },
  computed: {
    isDisabledContractIdField() {
      if (this.isAllowContractInDifferentListing) {
        return false;
      }

      return !this.listingId;
    },
    selectedContractId() {
      return this.selectedContract?.id || 0;
    },
    selectedTenantData() {
      const tenantData = this.selectedContract?.tenant;
      const personalData = tenantData?.personal_data;

      return {
        userId: tenantData?.user_id || 0,
        phoneNumber: tenantData?.phone_number || '',
        tenantName: tenantData?.name || '',
        email: tenantData?.email || '',
        gender: tenantData?.gender || '',
        job: tenantData?.job || '',
        idCardUrl: personalData?.image_url?.medium || '',
        idCardImageId: personalData?.id || 0,
      };
    },
  },
  methods: {
    selectContract(contract) {
      this.selectedContract = contract;
    },

    // Called from parent to get all inputed values in this component
    getValues() {
      return {
        ...this.$refs.tenantInformation.getValues(),
        old_contract_id: this.selectedContractId,
      };
    },
  },
};
</script>
