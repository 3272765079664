<template>
  <div class="payment-section">
    <bg-text size="title-4" class="payment-section__title"
      >Informasi Pembayaran</bg-text
    >

    <validation-observer ref="observer">
      <bg-grid>
        <bg-grid-item :col="6">
          <add-tenant-rent-type-field
            v-model="rentType"
            :disabled="disabled"
            :options="rentTypeOptions"
          />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <add-tenant-date-field
            v-model="checkinDate"
            id="paymentSectionDateInput"
            label="Tanggal Check-In"
            message="Tanggal akan digunakan untuk tanggal penagihan uang sewa"
            :disabled="isDisabledCheckinDateInput"
            :disabled-dates="disabledCheckinDates"
          />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <add-tenant-rent-duration-field
            v-model="rentDuration"
            :rent-type="rentType"
            :disabled="isDisabledDurationSelect"
          />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <add-tenant-room-select-field
            v-model="selectedRoomId"
            :checkin-date="checkinDate"
            :rent-type="rentType"
            :duration="rentDuration"
            :listing-id="listingId"
            :disabled="isDisabledRoomSelect"
            @room-changed="$emit('room-changed', $event)"
          />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <add-tenant-rent-price-field :value="rentPrice" />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <add-tenant-field
            rules="required"
            label="Metode Pembayaran"
            label-for="paymentSectionPaymentMethodSelect"
          >
            <bg-select
              id="paymentSectionPaymentMethodSelect"
              data-testid="paymentSectionPaymentMethodSelect"
              v-model="paymentMethod"
              :disabled="isDisabledPaymentMethodSelect"
              :options="paymentMethodOptions"
              placeholder="Pilih metode pembayaran"
            >
              <template v-slot:option="{ option }">
                <div class="payment-section__payment-method-option">
                  <bg-text size="body-2">{{ option.name }}</bg-text>
                  <bg-text>{{ option.price }}</bg-text>
                </div>
              </template>
            </bg-select>
          </add-tenant-field>
        </bg-grid-item>
      </bg-grid>
    </validation-observer>
  </div>
</template>

<script>
import { BgText, BgGrid, BgGridItem, BgSelect } from 'bangul-vue';
import { ValidationObserver } from 'vee-validate';
import dayjs from 'dayjs';

import AddTenantDateField from '@admin/pages/RoomAllotmentAddTenant/components/fields/AddTenantDateField';
import AddTenantRentDurationField from '@admin/pages/RoomAllotmentAddTenant/components/fields/AddTenantRentDurationField';
import AddTenantRentPriceField from '@admin/pages/RoomAllotmentAddTenant/components/fields/AddTenantRentPriceField';
import AddTenantRentTypeField from '@admin/pages/RoomAllotmentAddTenant/components/fields/AddTenantRentTypeField';
import AddTenantRoomSelectField from '@admin/pages/RoomAllotmentAddTenant/components/fields/AddTenantRoomSelectField';

import AddTenantField from '@/_admin/pages/RoomAllotmentAddTenant/components/AddTenantField';
import { dateFormatterToSend, rupiahFormatter } from '@/utils/formatter';
import { getCheckoutDateEstimation } from '@/_admin/pages/RoomAllotmentAddTenant/utils/checkoutDate';

export default {
  name: 'AddTenantPaymentSection',
  components: {
    BgText,
    BgGrid,
    BgGridItem,
    BgSelect,
    ValidationObserver,
    AddTenantField,
    AddTenantDateField,
    AddTenantRentDurationField,
    AddTenantRentPriceField,
    AddTenantRentTypeField,
    AddTenantRoomSelectField,
  },
  props: {
    listingId: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rentPrice: {
      type: Number,
      default: 0,
    },
    rentTypeOptions: {
      type: Array,
      required: true,
    },
    priceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rentType: '',
      checkinDate: null,
      rentDuration: 0,
      selectedRoomId: '',
      paymentMethod: '',
    };
  },
  watch: {
    listingId() {
      this.rentType = '';
      this.paymentMethod = '';
      this.rentDuration = 0;
      this.resetValidation();
    },
    rentType(rentType) {
      this.$emit('rent-type-changed', rentType);
      this.checkinDate = null;
      this.paymentMethod = '';
    },
    checkinDate() {
      this.rentDuration = 0;
    },
    rentDuration(duration) {
      this.selectedRoomId = 0;
    },
  },
  computed: {
    disabledCheckinDates() {
      return {
        to: dayjs().startOf('day').toDate(),
      };
    },
    isDisabledCheckinDateInput() {
      return this.disabled || !this.rentType;
    },
    isDisabledDurationSelect() {
      return this.disabled || !this.rentType || !this.checkinDate;
    },
    isDisabledRoomSelect() {
      return (
        this.disabled ||
        this.isDisabledCheckinDateInput ||
        this.isDisabledCheckinDateInput ||
        !this.rentDuration
      );
    },
    paymentMethodOptions() {
      const rentPrice = rupiahFormatter(this.rentPrice);

      const options = [
        {
          label: `Full Payment - ${rentPrice}`,
          name: 'Full Payment',
          price: rentPrice,
          val: 'full',
        },
      ];

      const downPayment = this.priceData?.[this.rentType]?.downPayment;

      if (downPayment?.price) {
        const priceFormatted = rupiahFormatter(downPayment.price);

        options.push({
          label: `${downPayment.label} - ${priceFormatted}`,
          name: downPayment.label,
          price: priceFormatted,
          val: 'dp',
        });
      }

      return options;
    },
    isDisabledPaymentMethodSelect() {
      return this.disabled || !this.selectedRoomId;
    },
  },
  methods: {
    getCheckoutDate() {
      const checkoutDate = getCheckoutDateEstimation({
        checkinDate: this.checkinDate,
        duration: this.rentDuration,
        rentCountType: this.rentType,
      });

      return checkoutDate ? dateFormatterToSend(checkoutDate) : '';
    },
    // Called from parent to get all inputed values in this component
    getValues() {
      return {
        rent_count_type: this.rentType,
        checkin: dateFormatterToSend(this.checkinDate),
        checkout: this.getCheckoutDate(),
        duration: this.rentDuration,
        first_payment_type: this.paymentMethod,
        designer_room_id: this.selectedRoomId,
      };
    },
    resetValidation() {
      this.$refs.observer.reset();
    },
  },
};
</script>

<style lang="scss" scoped src="./AddTenantPaymentSection.scss"></style>
