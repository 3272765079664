<template>
  <div class="card-container">
    <div class="card-container__header">
      <bg-text size="title-5">{{ title }}</bg-text>

      <bg-button
        v-if="isShowActionButton"
        variant="primary-naked"
        @click="$emit('click-action')"
      >
        <bg-text underline size="button-md">{{ action }}</bg-text>
      </bg-button>
    </div>

    <bg-skeleton
      v-if="loading"
      data-testid="cardContainer_skeleton"
      height="80px"
      width="100%"
    />

    <div v-else-if="empty" class="card-container__empty">
      <bg-text class="bg-u-text-neutral-500 bg-u-mb-xl" size="body-2">
        {{ emptyStateDescription }}
      </bg-text>

      <bg-link
        :href="`/property-detail/${propertyId}/contract`"
        target="_blank"
        rel="noopener noreferrer"
      >
        <bg-button size="lg">Buka PMS KK</bg-button>
      </bg-link>
    </div>

    <div v-else class="card-container__body bg-u-scrollbar">
      <slot />
    </div>
  </div>
</template>

<script>
import { BgButton, BgSkeleton, BgText, BgLink } from 'bangul-vue';

export default {
  name: 'AdditionalFeeCardContainer',

  components: {
    BgButton,
    BgSkeleton,
    BgText,
    BgLink,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    empty: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    emptyStateDescription: {
      type: String,
      default: '',
    },
    propertyId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    isShowActionButton() {
      return !!this.action && !this.empty && !this.loading;
    },
  },
};
</script>

<style lang="scss" src="./AdditionalFeeCardContainer.scss" scoped></style>
