<template>
  <additional-fee-card-container
    class="additional-fee-table"
    title="Biaya Tambahan yang Tersedia di Kos"
    empty-state-description="
      Tidak ada biaya tambahan yang tersedia di kos ini. 
      Silakan buka PMS KK untuk menambahkan biaya tambahan opsional
    "
    :empty="!additionalFees.length"
    :loading="loading"
    :property-id="propertyId"
  >
    <div class="additional-fee-table__wrapper">
      <table class="additional-fee-table__table">
        <tr>
          <th width="50px"><bg-text size="body-3">No</bg-text></th>
          <th><bg-text size="body-3">Nama Biaya</bg-text></th>
          <th><bg-text size="body-3">Harga</bg-text></th>
        </tr>

        <tr v-for="(fee, i) in additionalFees" :key="fee.id">
          <td>
            <bg-text size="label-2">{{ i + 1 }}</bg-text>
          </td>
          <td>
            <bg-text size="label-2">{{ fee.name }}</bg-text>
          </td>
          <td>
            <bg-label-rainbow
              v-if="fee.pricing_method === 'defined_by_billing'"
              color="yellow"
            >
              Disesuaikan dengan Tagihan
            </bg-label-rainbow>
            <ul
              v-else
              :class="{
                'additional-fee-table__amounts': true,
                'additional-fee-table__amounts--list': fee.amounts.length > 1,
              }"
            >
              <li
                v-for="(amount, amountIndex) in fee.amounts"
                :key="amountIndex"
              >
                <bg-text size="label-2">{{ formatFeeLabel(amount) }}</bg-text>
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </div>
  </additional-fee-card-container>
</template>

<script>
import { BgLabelRainbow, BgText } from 'bangul-vue';

import { currencyFormatter } from 'Utils/formatter';
import {
  TIME_UNIT_LABEL,
  FEE_UNIT_LABEL,
} from '@admin/constants/additionalFee';

import AdditionalFeeCardContainer from '../AdditionalFeeCardContainer';

export default {
  name: 'AdditionalFeeTable',

  components: {
    BgLabelRainbow,
    BgText,
    AdditionalFeeCardContainer,
  },

  props: {
    additionalFees: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    propertyId: {
      type: Number,
      required: true,
    },
  },

  methods: {
    formatFeeLabel({ amount, time_unit: timeUnit, fee_unit: feeUnit }) {
      let label = `Rp${currencyFormatter(amount || 0)}`;

      if (timeUnit && TIME_UNIT_LABEL[timeUnit]) {
        label += ` / ${TIME_UNIT_LABEL[timeUnit]}`;
      }

      if (feeUnit && FEE_UNIT_LABEL[feeUnit]) {
        label += ` (Per ${FEE_UNIT_LABEL[feeUnit]})`;
      }

      return label;
    },
  },
};
</script>

<style lang="scss" src="./AdditionalFeeTable.scss" scoped></style>
