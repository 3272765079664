<template>
  <add-tenant-wrapper
    class="add-tenant-relocation"
    ref="wrapper"
    type-label="Relocation"
    type-label-postfix="ke"
    :listing-id.sync="listingId"
    @property-fetched="property = $event"
  >
    <template #form>
      <add-tenant-information-by-contract
        ref="tenantInformation"
        :listings="listings"
        :listing-id="listingId"
        is-allow-contract-in-different-listing
        @contract-changed="handleContractSelected"
      />

      <bg-divider class="add-tenant__divider" />

      <add-tenant-payment-section
        ref="payment"
        :disabled="!isValidPricesData"
        :listing-id="listingPropertyId"
        :rent-price="totalRentPrice"
        :rent-type-options="rentTypeOptions"
        :price-data="roomPrices || {}"
        @room-changed="selectedRoom = $event"
        @rent-type-changed="rentType = $event"
      />

      <bg-divider class="add-tenant__divider" />

      <add-tenant-additional-fee
        v-if="listingPropertyId"
        :additional-fees="listingAdditionalFees"
        :loading="isFetchingAdditionalFees"
        :property-id="propertyId"
        :listing-id="listingPropertyId"
        @change="additionalPrices = $event"
      />

      <bg-button
        variant="primary"
        size="lg"
        class="mt-32"
        @click="handleSaveButtonClicked"
        >Simpan</bg-button
      >
    </template>

    <bg-modal
      v-model="isShowConfirmationModal"
      desktop-size="sm"
      title="Anda akan melakukan relokasi penyewa"
      button-main-text="Lanjut relokasi"
      button-second-text="Kembali"
      @click-main-action="submitRelocation"
      @click-second-action="isShowConfirmationModal = false"
    >
      <bg-text
        size="body-2"
        data-testid="confirmModalDescription"
        class="add-tenant-relocation__confirm-description"
      >
        Penyewa <b>{{ tenantName }}</b> pindah dari
        <b>{{ currentRoomLabel }}</b> ke <b>{{ relocationTargetLabel }}</b
        >. Pastikan penagihan/pengembalian selisih harga sewa telah dilakukan.
      </bg-text>
    </bg-modal>

    <loading-overlay
      :value="isSubmittingRelocation || isFetchingListingPrices"
    />
  </add-tenant-wrapper>
</template>

<script>
import { mapMutations } from 'vuex';
import { BgButton, BgText, BgDivider, BgModal } from 'bangul-vue';

import bookingAPI from '@admin/api/endpoints/booking';
import { dateFormatterToSend, dayjs } from 'Utils/formatter';

import LoadingOverlay from '@molecules/LoadingOverlay';

import AddTenantWrapper from '../../components/AddTenantWrapper';
import AddTenantInformationByContract from '../../components/AddTenantInformationByContract';
import AddTenantPaymentSection from '../../components/AddTenantPaymentSection';
import AddTenantAdditionalFee from '../../components/AddTenantAdditionalFee';

import mixinAddTenantDataProvider from '../../mixins/addTenantDataProvider';

const DEFAULT_ERROR_MESSAGE = 'Terjadi galat, silakan coba lagi.';

export default {
  name: 'AddTenantRelocation',

  components: {
    BgButton,
    BgText,
    BgDivider,
    BgModal,
    LoadingOverlay,
    AddTenantWrapper,
    AddTenantInformationByContract,
    AddTenantPaymentSection,
    AddTenantAdditionalFee,
  },

  mixins: [mixinAddTenantDataProvider],

  data() {
    return {
      listingId: 0,
      property: null,
      selectedContract: null,
      selectedRoom: null,
      isShowConfirmationModal: false,
      isSubmittingRelocation: false,
    };
  },

  computed: {
    listings() {
      return this.property?.listings || [];
    },
    tenantName() {
      return this.selectedContract?.tenant?.name || '';
    },
    currentRoomLabel() {
      const contractRoom = this.selectedContract?.room;
      const listingName = contractRoom?.name;
      const roomName = contractRoom?.unit?.name;

      return this.getListingRoomLabel(listingName, roomName);
    },
    relocationTargetLabel() {
      const listingName = this.listingData?.name;
      const roomName = this.selectedRoom?.name;

      return this.getListingRoomLabel(listingName, roomName);
    },
  },

  methods: {
    ...mapMutations('calendarView', ['setFromSubmitNewBookingState']),

    getListingRoomLabel(listingName, roomName) {
      return [listingName, roomName].filter(value => value).join(' - ');
    },
    handleContractSelected(contract) {
      this.selectedContract = contract;
    },
    async handleSaveButtonClicked() {
      if (!this.$refs.wrapper?.validateForm) {
        return;
      }

      const isValid = await this.$refs.wrapper.validateForm();

      if (isValid) {
        this.isShowConfirmationModal = true;
      }
    },
    getFormattedAdditionalPrices() {
      return this.additionalPrices.map(({ label, price }) => ({
        key: label,
        value: price,
      }));
    },
    getRelocationParams() {
      const { tenantInformation, payment } = this.$refs;

      const params = {
        booking_type: 'relocated',
        room_id: this.listingPropertyId,
        additional_price: this.getFormattedAdditionalPrices(),
        ...tenantInformation.getValues(),
        ...payment.getValues(),
      };

      return params;
    },
    async submitRelocation() {
      this.isSubmittingRelocation = true;
      this.isShowConfirmationModal = false;

      try {
        const relocationParams = this.getRelocationParams();

        const res = await bookingAPI.postNewBooking(relocationParams);

        if (res?.status === 200) {
          this.setFromSubmitNewBookingState(true);
          this.$router.push({
            name: 'room-allotment-calendar-view',
            query: {
              property_id: this.propertyId,
              start_date: dateFormatterToSend(
                dayjs(relocationParams.checkin).startOf('month')
              ),
            },
          });
        } else {
          const errorMessage = res?.data?.error || DEFAULT_ERROR_MESSAGE;
          this.$toast.show(errorMessage);
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || DEFAULT_ERROR_MESSAGE;
        this.$toast.show(errorMessage);
        this.$error.report(error);
      } finally {
        this.isSubmittingRelocation = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./AddTenantRelocation.scss"></style>
