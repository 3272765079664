<template>
  <add-tenant-field
    rules="required"
    label="Nomor Kamar"
    label-for="roomSelect"
    ref="roomSelect"
  >
    <bg-select
      id="roomSelect"
      data-testid="roomSelect"
      v-model="localValue"
      :searchable="!!rooms.length"
      options-label-key="name"
      options-value-key="id"
      :disabled="disabled || isLoading"
      :options="rooms"
      :placeholder="placeholder"
    >
      <template #no-options>Kamar tidak tersedia</template>
    </bg-select>
  </add-tenant-field>
</template>

<script>
import { BgSelect } from 'bangul-vue';

import bookingAPI from '@admin/api/endpoints/booking';
import inputModelMixin from '@admin/pages/RoomAllotmentAddTenant/mixins/inputModel';
import { getCheckoutDateEstimation } from '@admin/pages/RoomAllotmentAddTenant/utils/checkoutDate';

import { dateFormatterToDisplay } from '@/utils/formatter';

export default {
  name: 'AddTenantRoomSelectField',

  mixins: [inputModelMixin],

  components: {
    BgSelect,
  },

  props: {
    initialRoomId: {
      type: [Number, String],
      default: 0,
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    listingId: {
      type: Number,
      default: 0,
    },
    rentType: {
      type: String,
      default: '',
    },
    checkinDate: {
      type: [Date, String],
      default: '',
    },
    duration: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      searchKeyword: '',
      isLoading: false,
      localValue: '',
      rooms: [],
    };
  },

  computed: {
    placeholder() {
      if (this.isLoading) {
        return 'Mohon menunggu, sedang memuat data.';
      }

      return 'Pilih kamar';
    },
    availabilityParams() {
      return {
        checkIn: this.checkinDate,
        rentType: this.rentType,
        duration: this.duration,
      };
    },
    selectedRoom() {
      const selectedRoomId = this.localValue;

      if (selectedRoomId && this.rooms.length) {
        return this.rooms.find(room => room.id === selectedRoomId);
      }

      return null;
    },
  },

  watch: {
    availabilityParams: {
      immediate: true,
      deep: true,
      handler(params) {
        const isFulfilled = Object.values(params).every(value => !!value);

        if (isFulfilled) {
          this.fetchAvailableRooms();
        } else {
          this.reset();
        }
      },
    },
    selectedRoom: {
      deep: true,
      handler(room) {
        this.$emit('room-changed', room);
      },
    },
    initialRoomId(roomId) {
      this.setInitialRoom(roomId);
    },
  },

  methods: {
    setInitialRoom(roomId) {
      if (!roomId) {
        return;
      }

      this.localValue =
        this.rooms.length && this.rooms.find(({ id }) => id === roomId)
          ? roomId
          : '';
    },
    getCheckoutDate() {
      const checkoutDate = getCheckoutDateEstimation({
        checkinDate: this.checkinDate,
        duration: this.duration,
        rentCountType: this.rentType,
      });

      return checkoutDate
        ? dateFormatterToDisplay(checkoutDate, 'YYYY-MM-DD')
        : '';
    },
    async fetchAvailableRooms() {
      if (!this.listingId) return;

      const validation = this.$refs.roomSelect?.$refs?.validationProvider;

      if (validation) {
        validation.reset();
      }

      this.isLoading = true;
      this.localValue = '';
      this.searchKeyword = '';
      this.rooms = [];

      try {
        const res = await bookingAPI.getListingAvailableRooms(this.listingId, {
          checkIn: dateFormatterToDisplay(this.checkinDate, 'YYYY-MM-DD'),
          checkOut: this.getCheckoutDate(),
          status: 'available',
        });

        this.rooms = res?.data?.room_units || [];

        this.setInitialRoom(this.initialRoomId);
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || 'Terjadi kesalahan saat memuat kamar';
        this.$toast.show(errorMessage);

        if (!error?.response) {
          this.$error.report(error);
        }
      } finally {
        this.isLoading = false;
      }
    },
    reset() {
      this.searchKeyword = '';
      this.localValue = '';
      this.rooms = [];
      this.isLoading = false;
    },
  },
};
</script>
