<template>
  <additional-fee-card-container
    class="additional-fee-input"
    action="Tambah"
    title="Biaya Tambahan yang Ditagihkan ke Tenant"
    empty-state-description="
      Untuk menambahkan biaya yang akan ditagihkan ke tenant, 
      harap tambahkan biaya kos ke PMS KK terlebih dulu.
    "
    :empty="!additionalFeeOptions.length"
    :loading="loading"
    :property-id="propertyId"
    @click-action="addNewFeeInput"
  >
    <div
      v-for="(additionalFee, i) in addedAdditionalFees"
      class="additional-fee-input__list-item"
      data-testid="additionalFeeInput_listItem"
      :key="i"
    >
      <add-tenant-field label="Nama">
        <bg-select
          v-model="additionalFee.id"
          options-value-key="id"
          options-label-key="name"
          placeholder="Pilih biaya tambahan"
          searchable
          :data-testid="`additionalFeeInput_name${i}_ddl`"
          :options="additionalFeeOptions"
        />
      </add-tenant-field>

      <add-tenant-field
        class="bg-u-ml-xxl"
        label="Subtotal"
        :immediate="!!additionalFee.id"
        :rules="{ price_format: true, required: !!additionalFee.id }"
        :label-for="`additionalFeeInput_price${i}_txt`"
      >
        <input-currency-masking
          :id="`additionalFeeInput_price${i}_txt`"
          v-model="additionalFee.price"
          prefix="Rp"
          placeholder="Masukkan harga"
          is-input-only
        />
      </add-tenant-field>

      <div class="additional-fee-input__list-item-action bg-u-ml-xxl">
        <bg-button-icon
          variant="tertiary-naked"
          icon="delete"
          size="lg"
          @click="deleteFee(i)"
        />
      </div>
    </div>
  </additional-fee-card-container>
</template>

<script>
import { BgButtonIcon, BgSelect } from 'bangul-vue';

import { cleanNumber } from 'Utils/formatter';

import InputCurrencyMasking from '@/components/molecules/InputCurrencyMasking';
import AddTenantField from '../../../AddTenantField';
import AdditionalFeeCardContainer from '../AdditionalFeeCardContainer';

const DEFAULT_FEE_VALUE = {
  id: null,
  price: '',
};

export default {
  name: 'AdditionalFeeInput',

  components: {
    BgButtonIcon,
    BgSelect,
    InputCurrencyMasking,
    AddTenantField,
    AdditionalFeeCardContainer,
  },

  props: {
    additionalFeeOptions: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    propertyId: {
      type: Number,
      required: true,
    },
    listingId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      addedAdditionalFees: [{ ...DEFAULT_FEE_VALUE }],
    };
  },

  computed: {
    validAdditionalFees() {
      return this.addedAdditionalFees.filter(
        ({ id, price }) => !!id && !!cleanNumber(price)
      );
    },
  },

  watch: {
    validAdditionalFees: {
      deep: true,
      handler(additionalFees) {
        this.$emit('change', this.formatAdditionalFees(additionalFees));
      },
    },
    listingId() {
      this.addedAdditionalFees = [{ ...DEFAULT_FEE_VALUE }];
    },
  },

  methods: {
    addNewFeeInput() {
      this.addedAdditionalFees.push({ ...DEFAULT_FEE_VALUE });
    },
    deleteFee(index) {
      this.addedAdditionalFees.splice(index, 1);

      if (!this.addedAdditionalFees.length) {
        this.$nextTick(() => {
          this.addNewFeeInput();
        });
      }
    },
    formatAdditionalFees(additionalFees) {
      return additionalFees.map(additionalFee => {
        const priceLabel =
          this.additionalFeeOptions.find(
            option => option.id === additionalFee.id
          )?.name || '';

        return {
          price: cleanNumber(additionalFee.price),
          label: priceLabel,
        };
      });
    },
  },
};
</script>

<style lang="scss" src="./AdditionalFeeInput.scss" scoped></style>
