<template>
  <div>
    <bg-text class="bg-u-mb-md" size="title-4">
      Informasi Biaya Tambahan
    </bg-text>

    <bg-grid>
      <bg-grid-item :col="6">
        <additional-fee-table
          :additional-fees="additionalFees"
          :loading="loading"
          :property-id="propertyId"
        />
      </bg-grid-item>

      <bg-grid-item :col="6">
        <additional-fee-input
          :additional-fee-options="additionalFees"
          :loading="loading"
          :property-id="propertyId"
          :listing-id="listingId"
          @change="$emit('change', $event)"
        />
      </bg-grid-item>
    </bg-grid>
  </div>
</template>

<script>
import { BgGrid, BgGridItem, BgText } from 'bangul-vue';

import AdditionalFeeTable from './components/AdditionalFeeTable';
import AdditionalFeeInput from './components/AdditionalFeeInput';

export default {
  name: 'AddTenantAdditionalFee',

  components: {
    BgGrid,
    BgGridItem,
    BgText,
    AdditionalFeeTable,
    AdditionalFeeInput,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    additionalFees: {
      type: Array,
      required: true,
    },
    propertyId: {
      type: Number,
      required: true,
    },
    listingId: {
      type: Number,
      required: true,
    },
  },
};
</script>
