<template>
  <add-tenant-field-with-options
    id="contractId_txt"
    ref="contractIdInput"
    v-model="keyword"
    name="Contract ID"
    label="Contract ID"
    placeholder="Masukkan contract ID lama"
    :disabled="disabled"
    :rules="validationRules"
    :value-id="selectedContractId"
    :is-loading="isLoading"
    :is-loaded="isLoaded"
    :options="contractOptions"
    @input="emitKeywordInput"
    @select="selectContract"
    @focus="watchKeywordChange"
    @blur="unwatchKeywordChange"
    v-bind="$attrs"
  />
</template>

<script>
import { computed, getCurrentInstance, ref, watch } from '@vue/composition-api';
import axios from 'axios';
import { validate } from 'vee-validate';
import debounce from 'lodash/debounce';

import bookingAPI from '@admin_endpoints/booking';

import AddTenantFieldWithOptions from '../AddTenantFieldWithOptions';

const KEYWORD_CHANGE_FETCH_WAIT_TIME = 500;

export default {
  name: 'AddTenantContractIdInput',

  components: {
    AddTenantFieldWithOptions,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isAllowContractInDifferentListing: {
      type: Boolean,
      default: false,
    },
    listingId: {
      type: Number,
      default: 0,
    },
    listings: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const { $error } = instance.proxy;

    const keyword = ref(props.value);

    watch(
      () => props.value,
      value => {
        if (value !== keyword.value) {
          keyword.value = value;
        }
      }
    );

    const emitKeywordInput = value => {
      emit('input', value);
    };

    const selectedContract = ref(null);

    watch(
      selectedContract,
      contract => {
        emit('select', contract);
      },
      { deep: true }
    );

    const selectedContractId = computed(() => selectedContract.value?.id || 0);
    const contractStatus = computed(() => selectedContract.value?.status || '');

    const selectContract = contract => {
      selectedContract.value = contract?.data || null;
    };

    const isLoading = ref(false);
    const isLoaded = ref(false);
    const contractOptions = ref([]);

    let fetchContractToken = null;

    const getFetchContractToken = () => {
      const { CancelToken } = axios;
      fetchContractToken = CancelToken.source();

      return fetchContractToken.token;
    };

    const cancelFetchContractToken = () => {
      if (fetchContractToken) {
        fetchContractToken.cancel();
        fetchContractToken = null;
      }
    };

    const getMatchedListingId = contract => {
      const contractRoomId = contract?.room?.id || 0;

      if (!contractRoomId) {
        return 0;
      }

      const matchedListing = props.listings.find(
        listing => listing.id === contractRoomId
      );

      return matchedListing?.song_id || 0;
    };

    const getContractOptions = responseData => {
      let isValidContractListing = true;

      if (!props.isAllowContractInDifferentListing) {
        const matchedListingId = getMatchedListingId(responseData);
        const isInSelectedListing = matchedListingId === props.listingId;
        isValidContractListing = isInSelectedListing;
      }

      if (isValidContractListing) {
        return [
          {
            id: responseData.id,
            label: `${responseData.id} | ${responseData.tenant.name}`,
            data: responseData,
          },
        ];
      }

      return [];
    };

    const fetchContractById = async id => {
      isLoading.value = true;

      try {
        const res = await bookingAPI.getBookingContractById(
          id,
          getFetchContractToken()
        );

        const { status: responseStatus, data: responseData } = res?.data || {};

        if (responseStatus && responseData) {
          contractOptions.value = getContractOptions(responseData);
        }

        isLoaded.value = true;
      } catch (error) {
        const errorResponse = error?.response?.data;

        const isCanceled = axios.isCancel(error);
        isLoaded.value = !isCanceled;

        if (!errorResponse && !isCanceled) {
          $error.report(error);
        }
      } finally {
        isLoading.value = false;
      }
    };

    const baseValidationRules = computed(() => ({
      required: props.required,
      numeric: true,
    }));

    const validationRules = computed(() => {
      const isNotFound = isLoaded.value && contractOptions.value.length === 0;
      const isNotSelected = isLoaded.value && !selectedContractId.value;
      const isNotTerminated =
        !!contractStatus.value && contractStatus.value !== 'terminated';

      return {
        ...baseValidationRules.value,
        contract_not_found: isNotFound,
        not_selected: isNotSelected,
        contract_is_not_terminated: isNotTerminated,
      };
    });

    const fetchContractByIdOnKeywordChanged = debounce(async value => {
      if (!value) {
        return;
      }

      const { valid } = await validate(value, baseValidationRules.value);

      if (valid) {
        fetchContractById(value);
      }
    }, KEYWORD_CHANGE_FETCH_WAIT_TIME);

    const resetContractState = () => {
      contractOptions.value = [];
      isLoaded.value = false;
      isLoading.value = false;
      selectContract(null);
      cancelFetchContractToken();
    };

    const unwatchKeyword = ref(null);

    const unwatchKeywordChange = () => {
      if (unwatchKeyword.value) {
        unwatchKeyword.value();
      }
    };

    const watchKeywordChange = () => {
      unwatchKeyword.value = watch(keyword, value => {
        resetContractState();
        fetchContractByIdOnKeywordChanged(value);
      });
    };

    return {
      keyword,
      isLoading,
      isLoaded,
      contractOptions,
      validationRules,
      selectedContractId,
      selectContract,
      emitKeywordInput,
      watchKeywordChange,
      unwatchKeywordChange,
    };
  },
};
</script>
