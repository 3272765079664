import { makeRequest } from './index.js';

const endpoints = {
  getAdditionalFeeList: async params => makeRequest('GET', `/singgahsini/api/additional-fee`, params),
  getAdditionalFeeOptions: async () => makeRequest('GET', '/singgahsini/api/additional-fee/list-option-value'),
  validateFeeName: async params => makeRequest('GET', '/singgahsini/api/additional-fee/validate-fee-name', params, null, false),
  createAdditionalFee: async data => makeRequest('POST', '/singgahsini/api/additional-fee', null, data),
  deleteAdditionalFee: async additionalFeeId  => makeRequest('DELETE', `/singgahsini/api/additional-fee/${additionalFeeId}`),
  getPropertyAdditionalFeeList: async (propertyId, params) => makeRequest(
    'GET', `/singgahsini/api/property/${propertyId}/additional-fee`, params
  ),
  getPropertyAdditionalFeeFormData: async propertyId => makeRequest(
    'GET', `/singgahsini/api/property/${propertyId}/additional-fee/create`
  ),
  checkPropertyForAdditionalFeeExistence: async (propertyId, additionalFeeID) =>
    makeRequest(
      'GET',
      `/singgahsini/api/property/${propertyId}/additional-fee/${additionalFeeID}/detail`
    ),
  createPropertyAdditionalFee: async (propertyId, data) =>
    makeRequest(
      'POST',
      `/singgahsini/api/property/${propertyId}/additional-fee/store`,
      null,
      data,
      false,
    ),
  deleteAdditionalFeeGeneralLevel: async ({
    propertyId,
    additionalFeeRuleId,
  }) =>
    makeRequest(
      'DELETE',
      `/singgahsini/api/property/${propertyId}/additional-fee/${additionalFeeRuleId}`
    ),
  deleteAdditionalFeeListingLevel: async ({
    propertyId,
    additionalFeeRuleId,
    roomId
  }) =>
    makeRequest(
      'DELETE',
      `/singgahsini/api/property/${propertyId}/additional-fee/${additionalFeeRuleId}/listing/${roomId}`
    ),
  editPropertyAdditionalFeeGeneralLevel: async ({
    propertyId,
    additionalFeeRuleId,
    data,
  }) =>
    makeRequest(
      'PUT',
      `/singgahsini/api/property/${propertyId}/additional-fee/${additionalFeeRuleId}`,
      null,
      data,
      false
    ),
  editPropertyAdditionalFeeListingLevel: async ({
    propertyId,
    additionalFeeRuleId,
    roomId,
    data,
  }) =>
    makeRequest(
      'PUT',
      `/singgahsini/api/property/${propertyId}/additional-fee/${additionalFeeRuleId}/listing/${roomId}`,
      null,
      data,
      false
    ),
};

export { endpoints };
