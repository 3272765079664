import userAPI from '@admin_endpoints/user';
import { endpoints } from '@admin_endpoints/additional-fee';
import { cleanNumber } from 'Utils/formatter';
import { RENT_TYPE_OPTIONS } from '../constants/BookingData';

export default {
  data() {
    return {
      isFetchingListingPrices: false,
      isFailedFetchingPrices: false,
      isFetchingAdditionalFees: false,
      listingPrices: {},
      rentType: '',
      listingId: 0,
      selectedRoom: {},
      property: null,
      additionalPrices: [],
      additionalFeesByListingId: {},
    };
  },

  computed: {
    propertyId() {
      return +this.$route.params.propertyId || 0;
    },
    selectedRoomName() {
      return this.selectedRoom?.name;
    },
    roomPrices() {
      return this.listingPrices[this.listingId] || null;
    },
    rentTypePrices() {
      return this.roomPrices?.[this.rentType] || null;
    },
    roomPrice() {
      return this.getPrice('price');
    },
    totalRentPrice() {
      if (!this.rentTypePrices) {
        return 0;
      }

      return this.sumPrices([
        this.roomPrice,
        this.deposit,
        this.flashSalePrice,
        ...this.additionalPrices,
      ]);
    },
    deposit() {
      return this.getPrice('deposit');
    },
    flashSalePrice() {
      return this.getPrice('flashSale');
    },
    dpPrice() {
      return this.getPrice('downPayment');
    },
    rentTypeOptions() {
      return RENT_TYPE_OPTIONS.filter(rentType => {
        const rentTypePrice = this.getPrice('price', rentType.val);
        return !!rentTypePrice?.price;
      });
    },
    listingData() {
      const listings = this.property?.listings || [];
      return listings.find(listing => listing.song_id === this.listingId);
    },
    listingPropertyId() {
      return this.listingData?.id || 0;
    },
    listingAdditionalFees() {
      return this.additionalFeesByListingId[this.listingPropertyId] || [];
    },
    isValidPricesData() {
      return (
        !!this.listingId &&
        !this.isFetchingListingPrices &&
        !this.isFailedFetchingPrices &&
        !!this.roomPrices
      );
    },
  },

  watch: {
    listingId: {
      immediate: true,
      handler(id) {
        if (id && !this.listingPrices[id]) {
          this.fetchListingPrices(id);
        }
      },
    },
  },

  created() {
    this.fetchAdditionalFees();
  },

  methods: {
    async fetchAdditionalFees() {
      this.additionalFeesByListingId = {};
      this.isFetchingAdditionalFees = true;

      const params = {
        /**
         * Currently, to prevent pagination the limit is set to 100
         * because the API does not support a response without pagination
         */
        limit: 100,
      };

      const res = await endpoints.getPropertyAdditionalFeeList(
        this.propertyId,
        params
      );

      if (res.additional_fees) {
        this.composeAdditionalFeesByListingId(res.additional_fees);
      } else {
        this.$toast.show('Terjadi kesalahan saat memuat biaya tambahan');
      }

      this.isFetchingAdditionalFees = false;
    },
    composeAdditionalFeesByListingId(additionalFees) {
      additionalFees.forEach(({ rooms, ...additionalFeeDetails }) => {
        rooms.forEach(room => {
          const additionalFeeData = {
            ...additionalFeeDetails,
            amounts: room.amounts,
          };

          if (this.additionalFeesByListingId[room.id]) {
            this.additionalFeesByListingId[room.id].push(additionalFeeData);
          } else {
            this.additionalFeesByListingId[room.id] = [additionalFeeData];
          }
        });
      });
    },
    async fetchListingPrices(listingId) {
      this.isFetchingListingPrices = true;
      this.isFailedFetchingPrices = false;

      try {
        const res = await userAPI.getUserBookingDraftPrice(listingId);
        const { status, data, meta } = res?.data || {};

        if (status && data) {
          this.composeListingPrices(listingId, data);
        } else {
          this.isFailedFetchingPrices = true;
          this.showFetchListingPricesErrorToast(meta?.message);
        }
      } catch (error) {
        this.$error.report(error);
        this.isFailedFetchingPrices = true;
        this.showFetchListingPricesErrorToast();
      } finally {
        this.isFetchingListingPrices = false;
      }
    },
    showFetchListingPricesErrorToast(message = '') {
      this.$toast.show(message || 'Terjadi kesalahan saat memuat data harga');
    },
    composePriceComponent(label, price) {
      return {
        label,
        price: Number(price) || 0,
      };
    },
    composeListingPrices(listingId, pricesData) {
      const pricesObj = {};

      pricesData.forEach(data => {
        const priceLabel = `Harga Sewa ${data.label}`;
        const price = this.composePriceComponent(priceLabel, data.price);

        const isFlashSale = !!data.is_flash_sale;
        const flashSaleLabel = `Promo ngebut ${data.flash_sale_percentage}%`;
        const flashSale = this.composePriceComponent(
          flashSaleLabel,
          isFlashSale ? data.flash_sale_discount : 0
        );

        const deposit = this.composePriceComponent('Deposit', data.deposit);
        const downPayment = this.composePriceComponent(
          'Uang Muka (DP)',
          data.original_down_payment
        );

        pricesObj[data.type] = {
          type: data.type,
          is_flash_sale: data.is_flash_sale,
          price,
          deposit,
          downPayment,
          flashSale,
        };
      });

      this.$set(this.listingPrices, listingId, pricesObj);
    },
    getPrice(key, rentType = this.rentType) {
      return (
        this.roomPrices?.[rentType]?.[key] || {
          label: '',
          price: 0,
        }
      );
    },
    sumPrices(prices) {
      return prices.reduce((sum, { price }) => {
        const priceNumber =
          typeof price === 'string' ? cleanNumber(price) : price;
        return sum + (Number(priceNumber) || 0);
      }, 0);
    },
  },
};
