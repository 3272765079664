export const TIME_UNIT_LABEL = {
  daily: 'Hari',
  weekly: 'Minggu',
  monthly: 'Bulan',
};

export const FEE_UNIT_LABEL = {
  kg: 'kg',
  person: 'orang',
  item: 'item/pcs',
};
